import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "path": "/walletless-onboarding",
  "date": "2022-09-23",
  "title": "Wallet-less Onboarding",
  "tags": ["Polkadot", "Onboarding"],
  "excerpt": "How integrated burner wallets can help onboard a new group of users"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`One of the main findings of the UX research my colleague Travis has conducted over the last couple of months, was that the most common blocker of user adoption is Polkadot’s poor onboarding. The majority of parachain teams see the onboarding experience as too complex and worse than that of other ecosystems. A usual onboarding flow would involve the user signing-up to an exchange first, possibly going through some kind of KYC process, and adding payment methods in order to then purchase DOT or a parachain token. To interact with the network in a decentralized manner, it would furthermore involve installing a browser wallet and transferring the funds to a newly created address. Only then are they able to connect to dapps and interact with them. This is a long and cumbersome process for any average Web2 user. If we want to improve Polkadot’s onboarding experience, we need to ask: how can we get users to action quicker?`}</p>
    <h3>{`Leveraging Gifts`}</h3>
    <p>{`We already introduced a crypto-native way of onboarding new users in the form of Gifts. Polkadot Gifts allows current users of the ecosystem to send DOT or KSM to family and friends in the form of 16-digit vouchers. The recipients can then go ahead, create an address in the app and redeem their voucher to fund their new address – all this is completely stateless and does not require them to sign up anywhere. Here is a video of how Gifts currently works:`}</p>
    <iframe src="https://player.vimeo.com/video/752029746" width="100%" height="400px" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
    <p>{`This is quite a powerful concept, but I feel that we are not leveraging this alternative on-ramp enough. Both, users and builders, are maybe not aware of the possibility, and we are relying on the generosity of the community, too. Additionally we have four different deployments, depending on the relay chain and type of gift. Furthermore, it is conceivable that we offer not only gifts in the form of native tokens or NFTs, but also for things like eg. membership to a society or keys to an event etc. There are many unexplored ideas here Parachains could leverage on to attract a new group of users. It would be also thinkable to sponsor those gifts as onboarding campaigns through the respective treasuries. Or reward the most generous community members with status badges.`}</p>
    <h3>{`Wallet-less onboarding`}</h3>
    <p>{`However, what seems straightforward to us, still might feel a bit overwhelming for new users. We introduce new concepts like a seed phrase, an on-chain voucher and a browser extension as a wallet with its own onboarding flow to them. One radical idea that has been emerging during the UX research process is the concept of a wallet-less onboarding. The idea being, that we create a temporary address under the hood, and let the user focus purely on the action (eg. claiming some NFTs or become members of a society or any other type of interaction). We are fully aware that this is horrible from a security point of view, but are willing to do that tradeoff as we talk about low-value assets or interactions explicitly here; this in-browser address is meant to be temporary and should not be used to hold vast amounts of assets. In a way, it is an in-browser burner wallet. Eventually, the user will be asked to export their address (or rather create a new one) and install an extension to import it into. We could write a timer after which the temporary wallet is deleted from the browser.`}</p>
    <p>{`Here is a little prototype of how a wallet-less onboarding flow might look like in the Gifts app:`}</p>
    <iframe src="https://player.vimeo.com/video/752035079" width="100%" height="400px" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
    <p>{`And here is a potential flow for a fictional NFT game:`}</p>
    <iframe src="https://player.vimeo.com/video/752044152" width="100%" height="400px" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
    <p>{`Again, we are aware that this is contrary to security best practices, but considering we are talking of a different audience, it might actually be the right approach for them. We wanted to share this concept to provoke some discussion, also on how feasible this is from a technical point of view, too. Excited to hear what you think!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      